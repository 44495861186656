import React, { useEffect } from "react";
import { useState } from "react";
import axios from "../../utils/axios";
const PrintersInfo = ({ item, departments }) => {
  const [selectedValue, setSelectedValue] = useState({});
  const [printerState, setPrinterState] = useState({
    prn_inv: item.prn_inv,
    model: item.model,
    prnid: item.prnid,
    prn_serial: item.prn_serial,
    dep_id: item.dep_id,
  });
  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
  };

  const findOptionByValue = (value) => {
    return departments.find((option) => option.id === value);
  };

  const updateSelectedValue = (newValue) => {
    const option = findOptionByValue(newValue);
    if (option) {
      setSelectedValue(option.value);
    }
  };
  useEffect(() => {
    const defaultSelect = departments.find(
      (item) => item.id === printerState.dep_id
    );

    setSelectedValue(defaultSelect);
  }, []);
  console.log(selectedValue);
  const updateDep = async ()=>{
    try {
 if (window.confirm(`Змінити відділ?`)) {
  const data = await axios.post('/cart/update-dep',{
    dep_id:selectedValue,
    prn_inv:item.prn_inv
  })
 }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="printer">
     <div>{printerState.prn_inv}</div>
      <div>{printerState.model}</div>
      <div>{printerState.prn_serial} </div>
      <select defaultValue={selectedValue.dep_name} value={selectedValue?.dep_name} onChange={handleSelectChange}>
        <option value={selectedValue.dep_name}>{selectedValue?.dep_name}</option>
        {departments.filter(item => item?.dep_name != selectedValue?.dep_name).map((item, idx) => {
          return (
            <option key={idx} value={item.id}>
              {item.dep_name}
            </option>
          );
        })}
      </select>
      <button onClick={updateDep} className="normal">Змінити</button>
    </div>
  );
};

export default PrintersInfo;
