import React from 'react'
import { useState } from 'react';
import axios from '../../utils/axios'
const CartridgeModel = ({item}) => {
    const [printerState, setPrinterState] = useState(item.model);
    const [deleteMess,setDeleteMess] = useState('')
      const changePrinterState = (newState) => {
        setPrinterState(newState);
      };
const updateCartridge = async ()=>{
    try {
    if (window.confirm(`Ви впевнені що хочете змінити ${item.model} на ${printerState} `)) {
        const data = await axios.post('/cart/update-cartridge',{
            id:item.id,
            model:printerState
        })
    
        if (data.data.command === 'UPDATE') {
            alert('Успішно змінено')
        }
    }
    } catch (error) {
        console.log(error);
    }
}
const deleteCartridge = async ()=>{
  
  try {
    
  if (window.confirm(`Ви впевнені що хочете видалити ${item.model}`)) {
      const data = await axios.post('/cart/delete-cartridge',{
          id:item.id,
          active:0,
          deleteMess: deleteMess ? deleteMess : "Не вказана причина"
      })
      if (data.data.command === 'UPDATE') {
          alert('Успішно змінено');
        
      }
      window.location.reload();
  }
  } catch (error) {
      console.log(error);
  }
}
  return (
    <div  className="printer__model">
   <div className="input">
   <div>{item.id}</div>
    <input value={printerState} onChange={e => changePrinterState(e.target.value)}/>
   </div>
   <button onClick={updateCartridge} className='normal'>Змінити</button>
   <button onClick={deleteCartridge} className='normal' style={{backgroundColor:"red"}}>Видалити</button>
 <textarea onChange={e => setDeleteMess(e.target.value)} style={{width:"200px"}} type="text" placeholder='Причина видалення.Опційно' /> 
  </div>
  )
}

export default CartridgeModel