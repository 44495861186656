import './EmailsSend.scss'
import EmailsSendTab from './EmailsSendTab'

const EmailsSend = () => {


  return (
    <div className="page emails_send">
        <div className="container">
    <EmailsSendTab/>



            
        </div>
    </div>
  )
}

export default EmailsSend