import React, { useState } from "react";
import {
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";

import { MdOutlinePriceChange } from "react-icons/md";
import { FaCubes } from "react-icons/fa";
import { FiTruck } from "react-icons/fi";
import { MarkerClusterer } from "@react-google-maps/api";
import { getTypeTruck } from "../../../helpers/MapFunctions/getTruckClass";


const TruckMarker = ({ item }) => {
  const [infowindowOpen, setInfowindowOpen] = useState(false);
  const [markerRef, marker] = useAdvancedMarkerRef();


  return (
    <>
      <AdvancedMarker
        onClick={() => setInfowindowOpen(true)}
        position={{ lat: parseFloat(item?.LAT), lng: parseFloat(item?.LON) }}
        ref={markerRef}
      
      >
        <div
          // onMouseEnter={() => setInfowindowOpen(true)}
          // onMouseLeave={() => setInfowindowOpen(false)}
          style={{
            width: 20,
            height: 20,
            position: "absolute",
            top: 0,
            left: 0,
            background:`rgba(255, 255, 255, 0.5)`,
            backgroundImage: `url(${getTypeTruck(item)})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            border: "1px solid black",
            borderRadius: "50%",
            transform: "translate(-50%, -50%)",
            padding:"2px"
          }}
        ></div>
      </AdvancedMarker>
      {infowindowOpen && (
        <InfoWindow
          position={"relative"}
          anchor={marker}
          maxWidth={200}
          onCloseClick={() => setInfowindowOpen(false)}
          fontSize={30}
        >
          <span style={{ color: "red" }}>{item.PIP}</span>
          <p>{item.PRIM} </p>
          <p style={{ color: "green", fontWeight: "bold" }}>{item.NTYPE} </p>
          <p>{item.PUNKT}</p>
        </InfoWindow>
      )}
    </>
  );
};

export default TruckMarker;
