import instance from "../../utils/axios";
import { useState, useEffect } from "react";
import { Audio } from "react-loader-spinner";

const ChoseEmailSend = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [finalSend,setFinalSend] = useState(false)
  const [finalSendingData,setFinalSendingData] = useState(null)
  const [list, setList] = useState([]);
  const [startSend, setStartSend] = useState(null);
  const [startSending, setStartSending] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [finalStart,setFinalStart] = useState(false)

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const getListOfMails = async () => {
    try {
      const data = await instance.get("/xls-files");

      setList(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const deleteOneList = async (item) => {
    try {
      const data = await instance.post("/delete-xls-file", { filename: item });

      if (data.status === 200) {
        getListOfMails();
        setDeleted(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getListOfMails();
  }, [deleted]);

  const handleStartSending = async () => {
 
    const obj = {
      type: selectedOption,
      commentTitle: title,
      commentText: text,
      documentTitle: startSend,
    };
 
    
    try {
      if (!selectedOption || !title || !text) {
        alert("Заповність усі поля");
      } else {
        setIsLoading(true);
        const data = await instance.post("/email/create-list", obj);

    
        

        setFinalSendingData(data.data)

        if (data.status === 200) {
          setStartSending(true);
          setIsLoading(false);
          setFinalSend(true)
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
const handleStartDirectSend = async ()=>{
    try {

 const obj = {
    title,text,table:finalSendingData?.data
 }

 setFinalStart(true)
 const data = await instance.post('/email/direct-send',obj)

    } catch (error) {
        console.log(error);
        
    }
}
  return (
    <>
      <div>Список розсилок</div>
      <div className="greeting__list">
        {
        
        list &&
          list.map((item, idx) => {
            return (
              <div
                className={`greeting__list-file ${
                  startSend === item ? "active" : ""
                }`}
                key={idx}
              >
                <span>{item}</span>
                <button onClick={() => setStartSend(item)}>Обрати</button>
                <button onClick={() => deleteOneList(item)}>Видалити</button>
              </div>
            );
          })
          
          
          }

 {finalSend ? null : 
 
 
 startSend && (
    <div className="start_sending">
      <h2>Налаштування розсилки</h2>
      <form>
        <div>
          <label>
            <input
              type="radio"
              value="per"
              name="type"
              checked={selectedOption === "per"}
              onChange={handleChange}
            />
            Перевізник
          </label>
        </div>
        <div>
          <label>
            <input
              type="radio"
              value="zam"
              name="type"
              checked={selectedOption === "zam"}
              onChange={handleChange}
            />
            Замовник
          </label>
        </div>
        <div>
          <label>
            <input
              type="radio"
              value="user"
              name="type"
              checked={selectedOption === "user"}
              onChange={handleChange}
            />
            Користувачам
          </label>
        </div>
      </form>
      <div className="start_sending-form">
        <input
          onChange={(e) => setTitle(e.target.value)}
          type="text"
          placeholder="Тема"
        />
        <textarea
          onChange={(e) => setText(e.target.value)}
          type="text"
          rows={10}
          placeholder="Напишіть текст для розсилки"
        />
        <button disabled={isLoading ? true : false} onClick={handleStartSending}>
          Зробити список розсилки
        </button>
      </div>
    </div>
  )}
 

        {isLoading && <>
        
        <div style={{ display: 'flex', flexDirection: 'column' }} className='info'>
      
          <Audio
            height="80"
            width="80"
            radius="9"
            color="green"
            ariaLabel="three-dots-loading"
            wrapperStyle
            wrapperClass
          />
    
        </div>
        </>}

        {startSending && (
        
        <div className="final_sending">
 
          <p>Листів до відправлення: {finalSendingData?.tableResult?.rowCount}</p>
          {finalStart ? null : <button disabled={finalStart ? true: false} onClick={handleStartDirectSend} style={{fontSize:'24px',color:'green',backgroundColor:'lightgrey'}}>{finalStart ? "Розсилка запущена": "Розпочати розсилку"}</button>}
      
        </div>
        )}
      </div>
    </>
  );
};

export default ChoseEmailSend;
