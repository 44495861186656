import ref from '../../assets/png/truck-icons/ref.png'
import tent from '../../assets/png/truck-icons/tent.png'
import bus from '../../assets/png/truck-icons/bus.png'
import isoterm from '../../assets/png/truck-icons/isoterm.png'

import photoImg from "../../assets/truck-icon.png";

export const getTypeTruck = (item)=>{
    switch (item.NTYPE) {
      case 'Рефрижератор':
        return ref
      
      case 'Тент':
        return tent
      case 'Бус':
        return bus
      case 'Ізотерм':
        return isoterm
     
      default:
        return photoImg
       
    }
  }