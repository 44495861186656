import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";

const initialState = {
    distance:{
        distance: null
    }
  };


  const distanceSlice = createSlice({
    name:"distance",
    initialState,
    reducers:{
        changeDistance:(state,action) =>{
            state.distance.distance = action.payload
        }
    }
  })

export const { changeDistance} = distanceSlice.actions
export const distanceReducer = distanceSlice.reducer