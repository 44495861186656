import { useDispatch } from "react-redux";
import "./Printers.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useSelector } from "react-redux";
import {
  fetchCart,
  fetchCartChange,
  fetchCartModels,
  fetchCartriges,
  fetchPrinters,
} from "../../redux/slices/cartriges";
import { useEffect } from "react";
import axios from "../../utils/axios";
import { useState } from "react";
import PrinterModel from "./PrinterModel";
import PrintersInfo from "./PrintersInfo";
import CartridgeModel from "./CartridgeModel";
import CartChange from "./CartChange";
import moment from "moment";
import toTimestamp from "../../helpers/functions";
import AddChangeCart from "./AddChangeCart";
const PrintersMain = () => {
  const cart = useSelector((state) => state.cart.cart.items);
  const [deps, setDeps] = useState([]);
  const dispatch = useDispatch();
  const [imageBlob, setImageBlob] = useState(null);
  const [modelPrinter, setModelPriter] = useState("");
  const [printerModel, setPrinterModel] = useState("");
  const [cartridgeModel, setCartridgeModel] = useState("");
  const [cartModels, setCartModels] = useState("");
  const [cartModelList, setCartModelList] = useState("");
  const [printersList, setPrintersList] = useState(null);
  // console.log(cartModelList);

  useEffect(() => {
    dispatch(fetchCart());
  }, []);
  useEffect(() => {
    axios
      .get("/photo", { responseType: "arraybuffer" })
      .then((response) => {
        setImageBlob(new Blob([response.data], { type: "image/jpeg" }));
      })
      .catch((error) => {
        console.error("Error fetching LOB data:", error);
      });
  }, []);
  const getAllDepartmetns = async () => {
    try {
      const { data } = await axios.get("/cart/departments");
      setDeps(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllDepartmetns();
  }, []);
  const getAllCartModels = async () => {
    try {
      const { data } = await axios.get("/cart/cart-model");
      setCartModels(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllCartModels();
  }, []);
  // console.log(cartModels);
  const addPrnModel = async () => {
    try {
      if (window.confirm("Додати модель принтера ?")) {
        const data = await axios.post("/cart/add-cartridge", {
          model: printerModel,
        });
        if (data.status === 200) {
          window.location.reload();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const addCartModel = async () => {
    try {
      if (window.confirm("Додати модель картриджа ?")) {
        const data = await axios.post("/cart/add-cartridge-model", {
          model: cartridgeModel,
        });
        if (data.status === 200) {
          window.location.reload();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // console.log('cart_models',cartModels);

  useEffect(() => {
    const getPrinters = async () => {
      try {
        const data = await axios.get("/cart/printers-list");
        // console.log(data.data.rows);

        if (data.status === 200) {
          setPrintersList(data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getPrinters();
  }, []);

  console.log(cart);
  return (
    <div className="printers container">
      <Tabs>
        <TabList>
          <Tab onClick={() => dispatch(fetchCart())}>Моделі принтерів</Tab>
          <Tab onClick={() => dispatch(fetchCartModels())}>
            Моделі картриджів
          </Tab>
          <Tab onClick={() => dispatch(fetchPrinters())}>Принтери</Tab>
          <Tab onClick={() => dispatch(fetchCartriges())}>Картриджі</Tab>
          <Tab onClick={() => dispatch(fetchCartChange())}>
            Заміна картриджів
          </Tab>
        </TabList>
        <TabPanel>
          <div className="add_printer">
            <input
              type="text"
              value={printerModel}
              onChange={(e) => setPrinterModel(e.target.value)}
            />
            <button className="normal" onClick={addPrnModel}>
              Додати модель принтера
            </button>
          </div>
          {cart &&
            cart
              ?.filter((item) => item.active !== 0)
              .sort((a, b) => a.id - b.id)
              .map((item, idx) => {
                return <PrinterModel key={idx} item={item} />;
              })}
        </TabPanel>
        <TabPanel>
          <div className="add_printer">
            <input
              type="text"
              value={cartridgeModel}
              onChange={(e) => setCartridgeModel(e.target.value)}
            />
            <button className="normal" onClick={addCartModel}>
              Додати модель картриджа
            </button>
          </div>
          {cart &&
            cart
              .filter((item) => item.active !== 0)
              .sort((a, b) => a.id - b.id)
              .map((item, idx) => {
                return <CartridgeModel key={idx} item={item} />;
              })}
        </TabPanel>
        <TabPanel>
          {cart &&
            cart
              .filter((item) => item)
              .sort((a, b) => b.prn_ind - a.prn_ind)
              .map((item, idx) => {
                return (
                  <PrintersInfo key={idx} item={item} departments={deps} />
                );
              })}
        </TabPanel>

        <TabPanel>
          <div className="add_printer">
            <input type="text" placeholder="Серійний номер" />
            <select onChange={(e) => setCartModelList(e.target.value)}>
              {cartModels.length > 0 &&
                cartModels
                  ?.filter((item) => item.active !== 0)
                  .map((item, idx) => {
                    return (
                      <option key={idx} value={item.model}>
                        {item.model}
                      </option>
                    );
                  })}
            </select>
            <button className="normal">Додати картридж</button>
          </div>
          {cart &&
            cart.map((item, idx) => {
              return (
                <div key={idx} className="printer">
                  <div>{idx + 1}</div>
                  <div>{item.model}</div>
                  <div>{item.cart_ser}</div>
                </div>
              );
            })}
        </TabPanel>

        <TabPanel>
          {/* <h2>В процесі розробки</h2> */}
          <AddChangeCart printersList={printersList} />
          {/* {cart && (
            <div className="cart_change-title">
              <div>Дата</div>
              <div>Принтер</div>
              <div>Стрий картрідж</div>
              <div>Новий картрідж</div>
              <div>Лічильник</div>
              <div>Коментар</div>
            </div>
          )}
          {cart &&
            cart
              .filter((item) => item)
              .sort(
                (a, b) =>
                  toTimestamp(b.date_change) - toTimestamp(a.date_change)
              )
              .map((item, idx) => {
                return (
                  <div key={idx} className="printer__change-cart">
                    <div>{moment(item.date_change).format("L")}</div>

                    {printersList &&
                      printersList
                        ?.filter((val) => val.id === item.prn_id)
                        .map((value, idx) => {
                          // console.log('dsadsasdsda VALUE',value);
                          return (
                            <div key={idx}>
                              {value.prn_serial} - {value.prn_inv}
                            </div>
                          );
                        })}

                    {cartModels &&
                      cartModels
                        ?.filter((val) => val.id === item.cartr_old_id)
                        .map((value, idx) => {
                          return <div key={idx}>{value.model}</div>;
                        })}

                    {cartModels &&
                      cartModels
                        ?.filter((val) => val.id === item.cartr_new_id)
                        .map((value, idx) => {
                          return <div key={idx}>{value.model}</div>;
                        })}

                    <div>{item.prn_counter}</div>
                    <div>{item.descr}</div>
                  </div>
                );
              })} */}

{cart && 
  <div className="">
    <table border="1">
      <thead>
        <tr className="">
          <th>Дата</th>
          <th>Принтер</th>
          <th>Стрий картрідж</th>
          <th>Новий картрідж</th>
          {/* <th>Коментар</th> */}
          {/* <th>Лічильник</th> */}
     
        </tr>
      </thead>
      <tbody>
        {cart
          .filter((item) => item)
          .sort(
            (a, b) =>
              toTimestamp(b.date_change) - toTimestamp(a.date_change)
          )
          .map((item, idx) => (
            <tr key={idx} className="">
              <td>{moment(item.date_change).format("L")}</td>

              {printersList &&
                printersList
                  ?.filter((val) => val.id === item.prn_id)
                  .map((value, idx) => (
                    <td key={idx}>
                      {value.prn_serial} - {value.prn_inv}
                    </td>
                  ))}

              {cartModels &&
                cartModels
                  ?.filter((val) => val.id === item.cartr_old_id)
                  .map((value, idx) => (
                    <td key={idx}>{value.model}</td>
                  ))}

              {cartModels &&
                cartModels
                  ?.filter((val) => val.id === item.cartr_new_id)
                  .map((value, idx) => (
                    <td key={idx}>{value.model}</td>
                  ))}

              {/* <td>{item.prn_counter  === null ? "0" : item.prn_counter}</td> */}
              {/* <td>{item.descr === null ? '-': item.descr}</td> */}
            </tr>
          ))}
      </tbody>
    </table>
  </div>
}
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default PrintersMain;
