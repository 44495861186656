import { useEffect, useState } from 'react';
import './WebSiteStatistic.scss'
import axios from '../../../utils/axios'
import moment from "moment";
import "moment/locale/uk";
import converLinkToPageName from '../../../helpers/convertLinkIntoPageName';
import { useSelector } from 'react-redux';

const WebSiteStatistic = () => {
    const [month, setMonth] = useState([])
    const [yearReport, setYearReport] = useState([])
    const [openYear, setOpenYear] = useState(false)
    const userData = useSelector((state) => state.auth.data);
    const getMonthStatistic = async () => {
        try {
            const { data } = await axios.get('/web/month-visitors');


            if (data) {
                setMonth(data)
            }
        } catch (error) {
            console.log(error);
        }
    }
    const getMonthStatisticReport = async () => {
        try {
            const { data } = await axios.get('/web/month-visitors-report');


            if (data) {
                setYearReport(data)
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
       
            getMonthStatisticReport()
     

    }, [])
    useEffect(() => {
      
            getMonthStatistic()


    }, [])

    console.log('MONTH', userData);

    // console.log(month);
    return (
        <div className='web-site-statistic page'>
            <div className="web-inner container">

                <div className="web-inner-statistic">
                    <button onClick={() => setOpenYear(val => !val)}>{openYear ? 'Закрити річну статистику' : 'Відкриити річну статистку'}</button>
                    {openYear ? yearReport?.map((item, idx) => {
                        return <div key={idx} className='year-report month-item'>
                            <div className='title'>{moment(item.month).format('MMMM').toUpperCase()} - {item.total_visits}</div>
                            <div style={{ display: 'flex', flexDirection: 'column' }} className='info'>
                                {item.page_visits.map((visit, idx) => {
                                    return <span key={idx}>{converLinkToPageName(visit.page)} - {visit.visits}</span>
                                })}
                            </div>
                        </div>
                    }) : null}
                </div>

                <div className="web-inner-body">
                    {month && month?.map((item, idx) => {
                        return <div key={idx} className='month-item'>
                            <h3>{moment(item.date).format('LL')}</h3>
                            <div className='info'>
                                {item.page_visits.map((visit, idx) => {
                                    return <span key={idx}>{converLinkToPageName(visit.page)} - {visit.visits}</span>
                                })}
                            </div>

                        </div>
                    })}
                </div>
            </div>

        </div>
    )
}

export default WebSiteStatistic