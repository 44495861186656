import React, { useEffect } from "react";
import "./PostService.scss";
import { useState } from "react";
import axios from '../../utils/axios'
import moment from "moment";
const PostService = () => {
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const [text,setText] = useState('')
  const [files,setFiles] = useState([])

  const [sended,setSended] = useState([])


useEffect(()=>{
console.log(selectedPhoto);
},[selectedPhoto])
console.log(selectedPhoto);
const handleUpload = async () => {
  try {
    const formData = new FormData();
    formData.append('photo', selectedPhoto);

    await axios.post('/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    console.log('Photo uploaded successfully!');
  } catch (error) {
    console.error('Error uploading photo:', error);
  }
};
const handlePhotoChange = (event) => {
  setSelectedPhoto(event.target.files[0]);
  const previewURL = URL.createObjectURL(event.target.files[0]);
  setPreviewURL(previewURL);

};
// useEffect(() => {
//   // Викликайте серверний API для отримання списку файлів
//   axios('/upload')
//     .then(response => response.json())
//     .then(data => {
//       setFiles(data.files); // Припустимо, що сервер відповідає об'єктом, який містить масив файлів
//     })
//     .catch(error => {
//       console.error('Помилка отримання файлів з сервера', error);
//     });
// }, []); // Пустий масив деп

const sendNewYearEmail = async (req,res)=>{
  try {
    const data = await axios.post('/email',{text:text})
 
  } catch (error) {
    console.log(error);
  }
}

const getAllEmailsCount = async ()=>{
  try {
    const data = await axios.get('/feedback/emails')
    console.log(data.data);
    setSended(data.data)
  } catch (error) {
    console.log(error);
  }
}

useEffect(()=>{
  getAllEmailsCount()
},[])
  return (
    <div className="post page">
      <div className="post__inner container">
        {sended?.length === 0 ? null : <p className="sended__value">Відправлено : {sended.length}</p> }
        <div className="post__nav">
          {/* <input type="file" onChange={handlePhotoChange} />
          <textarea type="text" onChange={e => setText(e.target.value)} /> */}
       {text.length > 5  &&    <button onClick={handleUpload} className="normal">Завантажити фото </button>}
         {/* <button onClick={sendNewYearEmail}>Розпочати розсилку для контрагентів</button> */}
        </div>
        {text && <p className="new-year-text">{text}</p>  }
        {previewURL && <img src={previewURL} alt="Preview" style={{ maxWidth: '100%', maxHeight: '700px' }} />}
      </div>
<div className="sended__box">
{sended.length <= 0 ? null : <p className="sended__value">Відправлено : {sended?.map((item,idx)=>{
        return <div className="greeting__email" key={idx}>
         <div>{item.email}</div>
         <div>{item.contact}</div>
         <div>{item.position}</div>
         <div>{item.company}</div>
          <div>{moment(item.date_send).format('LLLL')}</div>
        </div>
      })}</p> }
</div>
    </div>
  );
};

export default PostService;
