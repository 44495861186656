import React, { useState, useEffect } from "react";
import './Timer.scss'
function Timer({ startTime }) {
  const [timeRemaining, setTimeRemaining] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = new Date().getTime();
      const endTime = startTime + 2 * 60 * 60 * 1000; // Додаємо 2 години до початкового часу
      const remaining = endTime - currentTime;

      if (remaining <= 0) {
        clearInterval(intervalId);
        setTimeRemaining(0);
      } else {
        setTimeRemaining(remaining);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [startTime]);

  // Функція для конвертації мілісекунд в формат годин:хвилин:секунд
  const formatTime = (ms) => {
    const hours = Math.floor(ms / (1000 * 60 * 60));
    const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((ms % (1000 * 60)) / 1000);

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <div className="timer__zap" >

      <p className="countdown__timer-zap">{formatTime(timeRemaining)}</p>
    </div>
  );
}

export default Timer;
