import React, { useEffect, useState } from "react";
import instance from "../../utils/axios";
import ProgressBar from "../../components/progress-bar/ProgressBar";
import socket from "../../utils/socket";
import { IoPauseCircleOutline, IoPlayCircleOutline } from "react-icons/io5";
const DoneSendEmail = () => {
  const [data, setData] = useState([]);

  const getListDoneSending = async () => {
    try {
      const data = await instance.get("/email/emails-tables");
      setData(data.data);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getListDoneSending();
  }, []);
  useEffect(() => {
    // Створюємо інтервал і зберігаємо його ідентифікатор
    const intervalId = setInterval(() => {
      socket.emit("get_emails_send_info", "lol");
    }, 10000);

    // Очищаємо інтервал через 3 хвилини (180000 мілісекунд)
    setTimeout(() => {
      clearInterval(intervalId);
    }, 180000);
  }, []);
  useEffect(() => {
    socket.on("show_get_emails_send_data", (data) => {
      if (data) {
        setData(data);
      }
    });
  }, []);

  const playDirectSend = async (item) => {
    try {
      const data = await instance.post("/email/play-sending", {
        play: 0,
        send_id: item.table_name,
      });
      alert('Зачекайте декілька секунд')
    } catch (error) {
      console.log(error);
    }
  };
  const stopDirectSend = async (item) => {
    console.log(item);

    try {
      const data = await instance.post("/email/play-sending", {
        play: 1,
        send_id: item.table_name,
      });
      alert('Зачекайте декілька секунд')
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <h1>Завершені розсилки</h1>

      <div
        style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        className="info"
      >
        {data &&
          data?.map((item, idx) => {
            return (
              <div
                style={{
                  border: item.is_pause === 0 ? "1px solid green" : "1px solid red",

                  padding: "10px",
                  borderRadius: "10px",
                }}
                className="sending__id"
              >
                <span>
                  {(item.table_name.includes("zam") && "ЗАМОВНИКАМ") ||
                    (item.table_name.includes("per") && "ПЕРЕВІЗНИКАМ") ||
                    (item.table_name.includes("user") &&
                      "ОКРЕМИМ КОРИСТУВАЧАМ")}
                </span>
                <pre>{item.table_comment}</pre>

                {item?.true_count === item?.total_count ? (
                  <div className="stop__controll">
                    <span
                      style={{
                        backgroundColor: "green",
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      Завершено
                    </span>
                  </div>
                ) : (
                  <div className="stop__controll">
                    <IoPauseCircleOutline
                      onClick={() => stopDirectSend(item)}
                      size={30}
                      color={item.is_pause === 0 ? "gray" : "red"}
                    />
                    <IoPlayCircleOutline
                      onClick={() => playDirectSend(item)}
                      size={30}
                      color={item.is_pause === 0 ? "green" : "white"}
                    />
                  </div>
                )}
                <ProgressBar
                  true_count={item.true_count}
                  total_count={item.total_count}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default DoneSendEmail;
