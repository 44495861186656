import React, { useState } from "react";
import {
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import photoImg from "../../../assets/logo.png";
import { MdOutlinePriceChange } from "react-icons/md";
import { FaCubes } from "react-icons/fa";
import { FiTruck } from "react-icons/fi";
import { MarkerClusterer } from "@react-google-maps/api";

const ZapMarker = ({ item,randomOffset }) => {
  const [infowindowOpen, setInfowindowOpen] = useState(false);
  const [markerRef, marker] = useAdvancedMarkerRef();

  // const randomOffset = () => Math.random() / 60;
  return (
    <>
      <AdvancedMarker
        onClick={() => setInfowindowOpen(true)}
        position={{
          lat: parseFloat(item?.ZLAT),
          lng: parseFloat(item?.ZLON)
        }}
        ref={markerRef}
      >
        <div
        // onMouseOver={() => setInfowindowOpen(true)}
        // onMouseOut={() => setInfowindowOpen(false)}
          style={{
            width: 20,
            height: 20,
            position: "absolute",
            top: 0,
            left: 0,
            background:
              item.KOD_GROUP === 21
                ? `rgba(68, 188, 214, 0.8)`
                : `rgba(199, 70, 115, 0.8)`,
            // backgroundImage: `url(${photoImg})`,
            // backgroundSize: "contain",
            border: "2px solid #0e6441",
            borderRadius: "50%",
            transform: "translate(-50%, -50%)",
          }}
        ></div>
      </AdvancedMarker>
      {infowindowOpen && (
        <InfoWindow
          position={"relative"}
          anchor={marker}
          maxWidth={200}
          onCloseClick={() => setInfowindowOpen(false)}
          fontSize={30}
        >
          <span style={{ color: "red" }}>{item.PIP}</span>
          <p>
            {item.ZAV} - <br /> {item.ROZV}
          </p>
          <div className="map__zap-icon-block">
            {item.ZAPCINA === 1 && (
              <MdOutlinePriceChange title="Запит ціни" fontSize={30} />
            )}
            {item.ZBIR === 1 && (
              <FaCubes fontSize={30} title="Збірний вантаж" />
            )}
          </div>
          <div
            title="Кількість авто"
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
            className="map__zap-icon-kilam"
          >
            {" "}
            <FiTruck fontSize={30} />{" "}
            <span style={{ fontSize: "30px", fontWeight: "bold" }}>
              {item.KILAM}
            </span>
          </div>
          {item.ZAM ? (
            <span
              style={{
                backgroundColor: "lightblue",
                fontSize: "20px",
                padding: "4px",
                borderRadius: "10px",
              }}
              title="Замовник"
              className="map__zap-zam"
            >
              {item.ZAM}
            </span>
          ) : (
            <span style={{ color: "red" }}>Замовника не вказано</span>
          )}
          <p>{item.ZAPTEXT}</p>
        </InfoWindow>
      )}
    </>
  );
};

export default ZapMarker;
