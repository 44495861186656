import * as React from 'react';
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useState } from 'react';
function ControlPanel({GOOGLE_API_KEY,setShowDistance,distanceFrorm,distanceTo,setDistanceTo,setDistanceFrom}) {
const [zav,setZav] = useState('')

  return (
    <div className="control-panel">
      <h3>Markers and InfoWindows</h3>

      <div className="form__control">
        <GooglePlacesAutocomplete
          className="o"
          apiKey={GOOGLE_API_KEY}
          apiOptions={{
            language: "en",
          }}
          selectProps={{
            zav,
            onChange: setZav,
            placeholder: "Звідки",
          }}
        />
      </div>

      {/* <div className="form__control">
        <GooglePlacesAutocomplete
          apiKey="AIzaSyDddHSvr8KFFahBGyqLCQVxpjCsFw-p5ek"
          apiOptions={{
            language: "uk",
          }}
          selectProps={{
            distanceTo,
            onChange: setDistanceTo,
            placeholder: "Куди",
          }}
        />
      </div> */}
      {/* <button onClick={()=> setShowDistance(val => !val)}>Дивитись маршрут</button> */}
    </div>
  );
}

export default React.memo(ControlPanel);
