import './Tenders.scss'
import logistProImg from '../../assets/images/tenders/logist-pro.png'
import { height } from '@mui/system'
import { useEffect, useState } from 'react'
import axios from '../../utils/axios'
import { copyTextToClipboard } from '../../helpers/navigator'
const tenders = [
    {title:"Logist PRO", img:logistProImg}
]

const Tenders = () => {
    const [tenderData,setTenderData] = useState([])
    const [tenderName,setTenderName] = useState('')
    const [clientName,setClientName] = useState('')


    console.log('TENDER DATA',tenderData);
    
    const getLogistProData = async ()=>{
        try {
            const data = await axios.get('/tenders/logist-pro');
          if (data.status === 200) {
            setTenderData(data.data)
          }
            
        } catch (error) {
           
          console.log(error);
          
            
        }
    }



    useEffect(()=>{
        getLogistProData()
    },[])
    

    const writeDataToClipboard = (item) =>{
      return  copyTextToClipboard(`${item.price}\n` + `Тендер\n`  + `Дата завантаження ${item.loading_date}\n `  + `${item.route}`)
    }
  return (
    <div className='page tenders'>

        <div className="tenders__inner container">
<input type="text" style={{width:"300px",height:'40px'}} placeholder='Пошук по клієнту'  onChange={(e)=>setClientName(e.target.value)} />
          {tenders?.map((item,idx) =>{
            return <div onClick={()=> setTenderName(item.title)}  className='tender__name'   style={{backgroundImage:`url(${item.img})`}} key={idx}>
             
            </div>
          })}

          {tenderName ? <h1 style={{textAlign:'center'}}>{tenderName}</h1>  : null }


          <div className="tender__items">
         {tenderData && tenderData.filter( item =>{
             return clientName.toLowerCase() === '' ? item : item?.client.toLowerCase().includes(clientName)
          }).map((item,idx) =>{
            return <div onClick={() => writeDataToClipboard(item)} key={idx} className='tender__item'>
                   <div className='code'>{item.code}</div>
                    <div className='client'>{item.client}</div>
                    <div className='route'>
                        <span>{item.loading_location}</span>

                        <span>{item.unloading_location}</span>
                    </div>
                    <div className='route'>
               {item.route}
                    </div>
                    <div className='route'>
               {item.price}
                    </div>
   
            </div>
         })}
          </div>
        </div>
    </div>
  )
}

export default Tenders