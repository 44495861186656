import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import FileUpload from "../../components/file_upload/FileUpload";
import instance from "../../utils/axios";
import ChoseEmailSend from "./ChoseEmailSend";
import DoneSendEmail from "./DoneSendEmail";

const EmailsSendTab = () => {
  return (
    <>
      <Tabs>
        <TabList>
          <Tab>Створити розсилку</Tab>
          <Tab>Список розсилок</Tab>
          <Tab>Завершені розсилки</Tab>
        </TabList>

        <TabPanel>
          <div>Створити розсилку</div>
          <FileUpload />
        </TabPanel>

        <TabPanel>
          <ChoseEmailSend />
        </TabPanel>


        <TabPanel>
          <DoneSendEmail/>
        </TabPanel>
      </Tabs>
    </>
  );
};

export default EmailsSendTab;
