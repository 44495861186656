import React, { useEffect, useMemo, useState } from "react";
import "./MapPage.scss";
import { APIProvider, Map, MapProps } from "@vis.gl/react-google-maps";
import { GOOGLE_API_KEY } from "../../data/google_data";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchZap } from "../../redux/slices/zap";
import ZapMarker from "./markers/ZapMarker";
import { MarkerClusterer } from "@react-google-maps/api";
import ControlPanel from "./ControlPanel";
import axios from "../../utils/axios";
import TruckMarker from "./markers/TruckMarker";
import Directions from "./directions/Directions";
import { FcCloseUpMode, FcInfo } from "react-icons/fc";
import { MdClose } from "react-icons/md";
import tent from "../../assets/png/truck-icons/tent.png";
import isoterm from "../../assets/png/truck-icons/isoterm.png";
import ref from "../../assets/png/truck-icons/ref.png";
import bus from "../../assets/png/truck-icons/bus.png";

const MapPage = ({ mapControlOpen, setMapControlOpen }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.data);
  const zap = useSelector((state) => state.zap.zap.items);
  const [freeTruck, setFreeTruck] = useState([]);
  useEffect(() => {
    if (userData) {
      dispatch(fetchZap(userData.KOD));
    }
  }, [userData]);
  useEffect(() => {
    const getAllFreeTrucks = async () => {
      try {
        const data = await axios.get(`/zap/free-trucks`);
        setFreeTruck(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAllFreeTrucks();
  }, [userData]);

  const [distanceFrom, setDistanceFrom] = useState(null);
  const [distanceTo, setDistanceTo] = useState(null);
  const [showDistance, setShowDistance] = useState(false);

  const [openMapInfo, setOpenMapInfo] = useState(false);

  const randomOffset = () => Math.random() / 30;

  let newZap = [];
  for (let i = 0; i < zap.length; i++) {
    const element = zap[i];
    const modifiedElement = {
      ...element, // Копіюємо всі властивості елемента
      ZLON: element.ZLON + randomOffset(), // Змінюємо значення ZLON
      ZLAT: element.ZLAT + randomOffset(), // Змінюємо значення ZLON
    };
    newZap.push(modifiedElement);
  }

  const goToTopOfThePage = () => {
    setMapControlOpen(false);
    window.scrollTo(0, 0);
  };

  const closeModal = (e) =>{
    e.stopPropagation()
    e.preventDefault()
    setOpenMapInfo(false)
  }
  return (
    <div className="map page">
      <div
        className="map__control"
        style={{
          display: mapControlOpen ? "flex" : "none",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div className="map__work-space">
          <button onClick={() => setOpenMapInfo(true)} className="normal">
            Підказки{" "}
            <span>
              {" "}
              <FcInfo fontSize={20} />
            </span>
          </button>
        </div>
        <button className="danger" onClick={goToTopOfThePage}>
          Закрити панель
        </button>
      </div>
      <APIProvider
        apiKey={GOOGLE_API_KEY}
        libraries={["marker", "geometry", "places", "placesPanel"]}
      >
        <Map
          mapId={"bf51a910020fa25a"}
          defaultZoom={8}
          defaultCenter={{ lat: 49.0, lng: 31.0 }}
          gestureHandling={"greedy"}
          disableDefaultUI
          maxZoom={20}
        >
          {newZap &&
            newZap.map((item, idx) => {
              return <ZapMarker key={idx} item={item} />;
            })}

          {freeTruck &&
            freeTruck.map((item, idx) => {
              return <TruckMarker key={idx} item={item} />;
            })}
        </Map>
      </APIProvider>

      {openMapInfo && (
        <div onClick={(e) => closeModal(e)} className="map__info">
          <div onClick={e => e.stopPropagation()}  className="map__info-inner">
            <MdClose
              onClick={() => setOpenMapInfo(false)}
              className="close__btn"
              fill="white"
              fontSize={40}
              title="Закрити підказки"
            />
            <div className="map__info-box">
              <h2>Вільний транспорт <br /> (Дані з бази - оперативні заявки)</h2>
              <div className="box">
                <div className="box__img">
                  <img src={tent} alt="item" />
                </div>
                <span>Відображення авто ТЕНТ</span>
              </div>
              <div className="box">
                <div className="box__img">
                  <img src={isoterm} alt="item" />
                </div>
                <span>Відображення авто ІЗОТЕРМ</span>
              </div>
              <div className="box">
                <div className="box__img">
                  <img src={ref} alt="item" />
                </div>
                <span>Відображення авто РЕФРЕЖИРАТОР</span>
              </div>
              <div className="box">
                <div className="box__img">
                  <img src={bus} alt="item" />
                </div>
                <span>Відображення авто БУС</span>
              </div>
            </div>
            <div className="map__info-box">
            <h2>Вільний транспорт <br /> (Дані з CRM - актуальні завантаження)</h2>
              <div className="box">
                <div className="box__img" style={{backgroundColor:"rgba(68, 188, 214, 0.8)"}}>
    
                </div>
                <span>Відображення завантажень ПО ТЕРИТОРІЇ УКРАЇНИ (з CRM)</span>
              </div>
              <div className="box">
                <div className="box__img"style={{backgroundColor:"rgba(199, 70, 115, 0.8)"}} >
               
                </div>
                <span>Відображення завантажень МІЖНАРОДНИХ (з CRM)</span>
              </div>
            </div>

          </div>
        </div>
      )}
    </div>
  );
};

export default MapPage;
