import React from 'react';
import './ProgressBar.scss';

const ProgressBar = ({ true_count, total_count }) => {
  // Calculate the progress percentage
  const progress = (true_count / total_count) * 100;

  return (
    <div className="progress-container">
      <div className="progress-bar-background">
        <div
          className={`progress-bar-fill`}
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <div className="progress-text">
        {true_count}/{total_count} Прогрес ({Math.round(progress)}%)
      </div>
    </div>
  );
};

export default ProgressBar;
