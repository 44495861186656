import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "./ReactModal.scss";
import instance from "../../utils/axios";
import { useSelector } from "react-redux";
import { toast,Bounce } from "react-toastify";
const ReactModal = () => {

  const [open, setOpen] = useState(false);
  const [info,setInfo] = useState('')
const userData = useSelector((state) => state.auth.data);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);


  useEffect(()=>{

  },[userData])

  const sendPropose = async ()=>{

    try {
        const obj = {
            comment:info,
            pipfull:userData?.PIPFULL
        }
        const data = await instance.post('/feedback/create-new',obj);

        console.log(data);
        if (data.data.length > 0 & data.status === 200) {
          onCloseModal();
          toast.success('✉️ Успішно відправлено', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
            });
        }
        
    } catch (error) {
        console.log(error);
        
    }
  }
  return (
    <div className="react__modal">
      <button className="react__modal-button" onClick={onOpenModal}>
        Пропозиції
      </button>
      <Modal open={open} onClose={onCloseModal} center>
        <div className="modal__body">
          <h2>Напишіть свою пропозицію щодо покращення сервісу</h2>

          <textarea cols={50} rows={20}  onChange={e => setInfo(e.target.value)} />
         {info.length <= 0 ? null :  <button onClick={sendPropose} className="send__button">Надіслати</button>}
        </div>
      </Modal>
    </div>
  );
};

export default ReactModal;
