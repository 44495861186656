import { useEffect, useState } from "react";
import "./ZapEditForm.scss";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { editZapRedux } from "../../redux/slices/edit";
import axios from "../../utils/axios";
import socket from "../../utils/socket";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { Button, Switch } from "antd";
const ZapEditForm = () => {
  const userData = useSelector((state) => state.auth.data);
  const dispatch = useDispatch();
  const [zav, setZav] = useState("");
  const [rozv, setRozv] = useState("");
  const [zapText, setZapText] = useState("");
  const [zapKilAm,setZapKilAm] = useState(0)
  const zapEditData = useSelector((state) => state.edit.zapEditData);
 const [tzTypes,setTzTypes] = useState([])

 const tzTypeZap = tzTypes.filter(item => item.KOD === zapEditData?.pTzType)[0]
 const [selectedTypeTz,setSelectedTypeTz] = useState(tzTypeZap?.KOD || 51 )
 const [ntype,setNtype] = useState(null)
const [searchZam,setSearchZam] = useState('')
const [carriers,setCarriers] = useState([])
const [zam,setZam] = useState([])
const [zamData,setZamData] = useState([])
// console.log('ZAPEDIT',zapEditData);


 const [choseTzType,setChozeTzType] = useState(null)


 const handleChangeTypeTz = (e)=>{
  setSelectedTypeTz(e.target.value)
  setChozeTzType(e.target.value)
  const res = tzTypes.find(item => item.KOD == e.target.value)
  setNtype(res)


  setTimeout(()=>{
    console.log('NTYPE',ntype);
  },2000)

  
}

const handleZamData = (item) => {
  setZamData({
    zamName: item.NDOV,
    zamKod: item.KOD,
  });
  setZam((value) => !value);
  setSearchZam('')
  setCarriers([])
};


  const [zapPrice, setZapPrice] = useState(
    zapEditData?.zapCina === 1 ? true : false
  );
  const [zapPriceValue, setZapPriceValue] = useState(
    zapEditData?.zapCina === 1 ? 0 : 1
  );
  const [zapZbirBalue, setZapZbirValue] = useState(
    zapEditData?.zbirZap === 1 ? 0 : 1
  );

  const [selectedOption, setSelectedOption] = useState(
    zapEditData?.zapGroup || selectedOption
  );
  const handleEditZapCina = async () => {
    const obj = {
      pKodZap: zapEditData?.zapKod,
      pZapCina: zapPriceValue,
      pKodAuthor: zapEditData?.zapKodOs,
    };
    try {
      const data = await axios.post(`/zap/edit-zap-cina`, obj);
      socket.emit("editZapCina", obj);
 
    } catch (error) {
      console.log(error);
    }
  };
  const handleEditZapZam= async (e) => {
    e.preventDefault()
    const obj = {
      pKodZap: zapEditData?.zapKod,
      pKodZapZam: zamData.zamKod,
    };
    try {
      const data = await axios.post(`/zap/edit-zapzam`, obj);
      socket.emit("editZapZam", obj);
 
    } catch (error) {
      console.log(error);
    }
  };
  const handleEditZbirZap = async () => {
    const obj = {
      pKodZap: zapEditData?.zapKod,
      pZapZbir: zapZbirBalue,
      pKodAuthor: zapEditData?.zapKodOs,
    };
    try {
      const data = await axios.post(`/zap/edit-zapzbir`, obj);
      socket.emit("editZapZbir", obj);

    } catch (error) {
      console.log(error);
    }
  };
  const handleZapCinaChange = async (e) => {
    if (e === true) {
      setZapPriceValue(1);
      handleEditZapCina();

    } else {
      setZapPriceValue(0);
      handleEditZapCina();

    }
  };
  const handleZbirZapChange = async (e) => {
    if (e === true) {
      setZapZbirValue(1);
      handleEditZbirZap();

    } else {
      setZapZbirValue(0);
      handleEditZbirZap();

    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };




  // console.log(zapEditData);
  const handleEditForm = async (e) => {
    e.preventDefault();

    const obj = {
      pKodAuthor: zapEditData?.zapKodOs,
      pKodZap: zapEditData?.zapKod,
      pZav: zav.label,
      pRozv: rozv.label,
      pZapText: zapText,
      pKodAuthor: userData.KOD,
      pZav: zav.label,
      pRozv: rozv.label,
      pZapText: zapText,
      PIP: userData.PIP,
      zavInfo: zav,
      rozvInfo: rozv,
      pZapCina: zapEditData.zapCina ? 1 : 0,
      pKodZam: zapEditData?.zapKodZam || null,
      pKilAm: zapEditData?.pKilAm,
      pKodTzType:zapEditData?.pTzType
     
    };
    try {
      if (zav === "" || rozv === "" || zapText === "") {
        window.alert("Заповніть усі поля");
      } else {
        const data = await axios.post(`/zap/edit`, obj);
        if (data.status === 200) {
          socket.emit("editZap", obj);
          // alert(`Ви успішно редагували заявку № ${obj.pKodZap} `);
          dispatch(editZapRedux());
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleEditText = async (e) => {
    e.preventDefault();

    const obj = {
      pKodZap: zapEditData?.zapKod,
      pZapText: zapText,
    };
    try {
      if (zapText === "") {
        window.alert("Заповніть усі поля");
      } else {
        const data = await axios.post(`/zap/edit-text`, obj);
        if (data.status === 200) {
          socket.emit("editZapText", obj);
          // alert(`Ви успішно редагували текст до  заявки № ${obj.pKodZap} `);
          dispatch(editZapRedux());
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeCount = async (e) =>{
    e.preventDefault()
    const obj = {
      pKodAutor: zapEditData?.zapKodOs,
     pKodZap: zapEditData?.zapKod,
     pKilAm:zapKilAm ? +zapKilAm : +zapEditData?.pKilAm
    }

    try {
      const data = await axios.post('/zap/edit-kilam',obj);
      dispatch(editZapRedux());
          socket.emit("editKilAm", obj);
    } catch (error) {
      console.log(error);
    }
  }
  const handleChangeTzType = async (e) =>{
    e.preventDefault()
    const obj = {
      pKodTzType: choseTzType,
      pKodZap: zapEditData?.zapKod,
      pNtype:ntype?.NTYPE
  
    }


    try {
      const data = await axios.post('/zap/edit-tztype',obj);
    
      
      dispatch(editZapRedux());
          socket.emit("editTzType", obj);
    } catch (error) {
      console.log(error);
    }
  }

  const getZapTypes = async ()=>{
    try {
      const data = await axios.get('/zap/tztype');
  
      setTzTypes(data.data)
      
    } catch (error) {
      console.log(error);
      
    }
  }

  useEffect(() => {
    setZav(zapEditData?.zav);
    setRozv(zapEditData?.rozv);
    setZapText(zapEditData?.zapText);
    setZapKilAm(zapEditData?.pKilAm)
    
  }, [zapEditData]);
  useEffect(() => {}, [zapEditData]);
  useEffect(() => {
    getZapTypes()
  }, []);

  useEffect(()=>{
    setSelectedTypeTz(tzTypeZap?.KOD)
  },[tzTypeZap])

  useEffect(() => {
    if (searchZam.length > 2) {
      const getContrAgents = async (search) => {
        try {
          const { data } = await axios.post("/ur/all", { search: searchZam.toLowerCase() });
          setCarriers(data);
        } catch (error) {
          console.log(error);
        }
      };
      getContrAgents(searchZam);
    }
    })

console.log(zamData);

  
  return (
    <div className="zap__edit-form">
      {zapEditData && (
        <form className="zap__edit_form">
          <h3 style={{ color: "brown" }}>
            Необхідно знову внести місто завантаження та вивантаження. <br />
            Також можете виправити лише текст заявки. <br />
            Або натисніть кнопку Вийти з редагування
          </h3>
          <div className="form__control">
            <GooglePlacesAutocomplete
              className="okkk"
              apiKey="AIzaSyDddHSvr8KFFahBGyqLCQVxpjCsFw-p5ek"
              apiOptions={{
                language: "uk",
              }}
              value={zav}
              selectProps={{
                defaultInputValue: zav,
                isClearable: true,
                value: zav,
                onChange: setZav,
                placeholder: "Введіть місто завантаження",
              }}
            />
          </div>
          <div className="form__control">
            <GooglePlacesAutocomplete
              className="okkk"
              apiKey="AIzaSyDddHSvr8KFFahBGyqLCQVxpjCsFw-p5ek"
              apiOptions={{
                language: "uk",
              }}
              selectProps={{
                defaultInputValue: rozv,
                isClearable: true,
                value: rozv,
                onChange: setRozv,
                placeholder: "Введіть місто вивантаження",
              }}
            />
          </div>
          <div className="form__control">
            <textarea
              value={zapText}
              onChange={(e) => setZapText(e.target.value)}
              type="text"
              placeholder="Додаткова інформація по вантажу"
              name="download"
              cols="10"
              rows="5"
              style={{
                resize: "none",
                fontSize: "20px",
                outline: "none",
                border: "none",
                borderRadius: "10px",
                padding: "0.4rem",
              }}
            />
          </div>
          <div className="zap__edit_form-buttons">
            <button className="normal" onClick={handleEditForm}>
              Редагувати
            </button>
            <button className="normal" onClick={handleEditText}>
              Редагувати лише текст
            </button>
   
            <FormControl display="flex" alignItems="center">
              <FormLabel color={"white"} htmlFor="email-alerts" mb="0">
              
               Запит ціни
              </FormLabel>
              <Switch
                id="email-alerts"
                defaultChecked={zapEditData?.zapCina}
                onChange={handleZapCinaChange}
              />
            </FormControl>
            <FormControl display="flex" alignItems="center">
              <FormLabel color={"white"} htmlFor="email-alerts" mb="0">
             
             Збірний вантаж
              </FormLabel>
              <Switch
                id="email-alerts"
                defaultChecked={zapEditData?.zbirZap}
                onChange={handleZbirZapChange}
              />
            </FormControl>


          </div>
          <div className="zap__edit_form-buttons">
              <span style={{color:"white"}}>Тип транспорту: {tzTypeZap?.NTYPE}</span>
              <select onChange={handleChangeTypeTz} value={selectedTypeTz}  style={{width:'100%'}} name="tztype" id="tztype">
    {tzTypes && tzTypes.filter(item => item).sort((a,b) => b.SORTID > a.SORTID).map((item,idx) =>{
      return <option value={item.KOD}>{item.NTYPE}</option>
    })}
  </select>

{choseTzType && <button onClick={handleChangeTzType} className="normal">Змінити тип транспорту</button> }


          </div>
{/*           
          <div className="change__zam">
    
            <input type="text" value={searchZam} onChange={e => setSearchZam(e.target.value)} />
            <button onClick={handleEditZapZam}>Змінити замовника</button>
          </div>
          <div className="carriers__items">
            {carriers.length > 0
              ? carriers.map((item, idx) => {
                  return (
                    <div className="carriers__item" key={idx}>
                      <p className="">{item.NDOV}</p>

                      <span
                        onClick={(e) => handleZamData(item)}
                        className="normal"
                        style={{ cursor: "context-menu", padding: "0.4rem" }}
                      >
                        Обрати замовника
                      </span>
                    </div>
                  );
                })
              : "Напишіть назву компанії"}
          </div> */}
     
 
          <div className="zap__edit_form-buttons">

            <input type="number" value={zapKilAm} onChange={e => setZapKilAm(e.target.value)} />
            <button  className="normal" onClick={changeCount}>
              Редагувати к-сть авто
            </button>

          </div>
          <button style={{marginTop:'40px',width:'100%',backgroundColor:'red'}} onClick={() => dispatch(editZapRedux())} className="danger">
              Вийти з редагування
            </button>
        </form>
      )}
    </div>
  );
};

export default ZapEditForm;
