import React, { useEffect, useState } from "react";

import {
  APIProvider,
  Map,
  useMapsLibrary,
  useMap,
} from "@vis.gl/react-google-maps";

import { GOOGLE_API_KEY } from "../../data/google_data";
import { useDispatch } from "react-redux";
import { changeDistance } from "../../redux/slices/distances";
import { useSelector } from "react-redux";

const DirectionsMap = ({ item }) => {
    const {distance} = useSelector(state => state.distance.distance)
    console.log(distance);
  return (
    <div className="zap__map">
      <APIProvider apiKey={GOOGLE_API_KEY}>
        <Map
          defaultCenter={{ lat: 22.5, lng: -100.38 }}
          defaultZoom={1}
          gestureHandling={"greedy"}
          fullscreenControl={false}
        >
          <Directions item={item} />
        </Map>
        <div className="distance__text">
          {distance !== null && (
            <h2>
              Загальна відстань{" "}
              <span className="global__distance">{distance?.text}</span>{" "}
            </h2>
          )}
        </div>
      </APIProvider>
    </div>
  );
};
function Directions({ item }) {
  const map = useMap();
  const routesLibrary = useMapsLibrary("routes");
  const [directionsService, setDirectionsService] = useState();
  const [directionsRenderer, setDirectionsRenderer] = useState();
  const [routes, setRoutes] = useState([]);
  const [routeIndex, setRouteIndex] = useState(0);
  const selected = routes[routeIndex];
  const leg = selected?.legs[0];
console.log(leg?.distance);
const dispatch = useDispatch()

useEffect(()=>{
dispatch(changeDistance(leg?.distance))
},[leg])
  // Initialize directions service and renderer
  useEffect(() => {
    if (!routesLibrary || !map) return;
    setDirectionsService(new routesLibrary.DirectionsService());
    setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map }));
  }, [routesLibrary, map]);

  // Use directions service
  useEffect(() => {
    if (!directionsService || !directionsRenderer) return;

    directionsService
      .route({
        origin: `${item.ZLAT},${item.ZLON}`,
        destination: `${item.RLAT},${item.RLON}`,
        travelMode: "DRIVING",
        provideRouteAlternatives: true,
      })
      .then((response) => {
        directionsRenderer.setDirections(response);
        setRoutes(response.routes);
        console.log(response);
      });

    return () => directionsRenderer.setMap(null);
  }, [directionsService, directionsRenderer]);

  // Update direction route
  useEffect(() => {
    if (!directionsRenderer) return;
    directionsRenderer.setRouteIndex(routeIndex);
  }, [routeIndex, directionsRenderer]);

  if (!leg) return null;
console.log('leg',leg);
console.log(routes);
  return (
    <div className="directions">
      <h2>{selected.summary}</h2>
      <p>
        {leg.start_address.split(",")[0]} to {leg.end_address.split(",")[0]}
      </p>
      <p>Distance: {leg.distance?.text}</p>
      <p>Duration: {leg.duration?.text}</p>

      <h2>Other Routes</h2>
      <ul style={{position:"absolute",bottom:"0"}}>
        {routes.map((route, index) => (
      
          <li key={route.summary}>
       
            <button style={{display:"flex",gap:"10px"}} onClick={() => setRouteIndex(index)}>
            <span >Шлях {index + 1}</span>
              {route.summary}
              
            </button>
            
          </li>
        ))}
      </ul>
    </div>
  );
}
export default DirectionsMap;
