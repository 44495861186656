import { useEffect, useState } from 'react';
import './AdminWebZap.scss'
import axios from '../../../utils/axios'
import moment from "moment";
import "moment/locale/uk";
import { useSelector } from 'react-redux';
const AdminWebZap = () => {
    const [webZap, setWebZap] = useState([])
    const userData = useSelector((state) => state.auth.data);
    const getWebGuestZap = async () => {
        try {
            const { data } = await axios.get('/web/webguest-zap');

            if (data) {
                setWebZap(data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getWebGuestZap()
    }, [userData])



    return (
        <div className='page'>
            <div className="admin__webzap container">
                <div className="webzap__items">
                    {webZap && webZap.map((item, idx) => {
                        return <div key={idx} className='webzap-item'>
                            <div className='date__info'>{moment(item.ZAPDAT).format('LLLL').toUpperCase()}</div>
                            <div className='contact__info'>


                                <div><a href={`mailto:${item.GUESTEMAIL}`}>{item.GUESTEMAIL}</a></div>

                                <div>+{item.GUESTTEL}</div>
                                <div>{item.GUESTNAME}</div>


                            </div>
                            <div className='zap__info'>{item.ZAPTXT}</div>

                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default AdminWebZap