import { useEffect, useRef, useState } from "react";
import "./AddZap.scss";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../utils/axios";
import { fetchZap, addReduxZap } from "../../redux/slices/zap";
import socket from "../../utils/socket";
import { beep } from "../../helpers/audio";
import { changeAddZapSuccess, editZapAddSlice } from "../../redux/slices/edit";
// import { io } from "socket.io-client";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { Link } from "react-router-dom";
import AutoCompleteUr from "./AutoCompleteUr";
import { AiOutlineCloseCircle } from "react-icons/ai";

const AddZap = ({ selectedGroup, showAddZap, setAddZap }) => {
  const [tzType,setTzType] = useState([])
  const [selectedTypeTz,setSelectedTypeTz] = useState(51)
  const [perInfo,setPerinfo] = useState(false)
  const zap = useSelector((state) => state.zap.zap.items);
  const userData = useSelector((state) => state.auth.data);
  const [allInfo, setAllInfo] = useState(null);
  const [zav, setZav] = useState("");
  const [rozv, setRozv] = useState("");
  const [zapText, setZapText] = useState("");
  const [zapTextPriv, setZapTextPriv] = useState("");
  const [zapPerCina, setZapPerCina] = useState('0');
  const [zapType, setZapType] = useState(null);
  const [zam, setZam] = useState(false);
  const [zamData, setZamData] = useState(null);
  const [carriers, setCarriers] = useState([]);
  const [search, setSearch] = useState("");
  const [zapCina, setZapCina] = useState(false);
  const [countCar, setCountCar] = useState(1);
  const [zbir,setZbir] = useState(0)
  const dispatch = useDispatch();
  const options = ["one", "two", "three"];
  const defaultOption = options[0];
  const handleCountCarsChange =(e)=>{
    setCountCar(e.target.value);
  }
  console.log(tzType);
  

  const handleChangeTypeTz = (e)=>{
    setSelectedTypeTz(e.target.value)
  }
const getTzType = async ()=>{
  try {
    const data = await axios.get('/zap/tztype');

if (data.status === 200) {
  setTzType(data.data)
}

  } catch (error) {
    console.log(error);
    
  }
}
  const handleCheckboxChange = () => {
    setZapCina((prevChecked) => !prevChecked);
  };
  const handleZbirChange = () => {
    setZbir((prevChecked) => !prevChecked);

  };
  const handleZamData = (item) => {
    setZamData({
      zamName: item.NDOV,
      zamKod: item.KOD,
    });
    setZam((value) => !value);
  };
  const clearZamData = (item) => {
    setZamData(null);
    setZam((value) => !value);
    setSearch("");
  };


  console.log('SELECTEDTZTYPE',selectedTypeTz);


  
  const handleSubmitAddZap = async (e) => {
    e.preventDefault();
    e.stopPropagation()
    const tzName = tzType?.find(item => item.KOD == selectedTypeTz)
    console.log('TZNAME',tzName);
    const object = {
      pKodAutor: userData.KOD,
      pKodGroup: selectedGroup,
      pZav: zav.label,
      pRozv: rozv.label,
      pZapText: zapText,
      pZapTextPriv: zapTextPriv,
      pFrahtPer:zapPerCina,
      PIP: userData.PIP,
      zavInfo: zav,
      rozvInfo: rozv,
      pZapCina: zapCina ? 1 : 0,
      pZbir:+zbir,
      pKodZam: zamData?.zamKod || null,
      pKilAm:+countCar,
      pPunktZ:zav?.value?.terms[0].value,
      pPunktR:rozv?.value?.terms[0].value,
      pKodTzType:selectedTypeTz ? selectedTypeTz : 51,
      tzName:tzName?.NTYPE
    };

    try {
      if ((zav !== "" || rozv !== "" || zapType === null, zapText === "")) {
        alert("Заповніть усіполя");
      } else {
        const data = await axios.post("/zap/add", object);
        if (data.status === 200) {

          const dataKod = data.data.outBinds.pKodZap;
          const zamName = data.data.outBinds.pZamName;
          socket.emit("newZap", {
            ...object,
            ZAP_KOD: dataKod,
            ZAM_NAME: zamName,
          });
          dispatch(editZapAddSlice());
          dispatch(changeAddZapSuccess());
          setTimeout(() => {
            dispatch(changeAddZapSuccess());
          }, 4000);
        } else {
          alert("Виникла якась помилка");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {}, [zap]);

  useEffect(() => {
    if (search.length > 2) {
      const getContrAgents = async (search) => {
        try {
          const { data } = await axios.post("/ur/all", { search: search.toLowerCase() });
          setCarriers(data);
        } catch (error) {
          console.log(error);
        }
      };
      getContrAgents(search);
    }
    if (search.length === 0) {
      setTimeout(() => {
        setCarriers([]);
      }, 500);
    }
  }, [search]);

  useEffect(()=>{
getTzType()
  },[])
  return (
    <form onSubmit={handleSubmitAddZap} className="add__zap">
      {zam ? (
        <span className="zam__choose">
          {zamData.zamName}
          <span onClick={clearZamData}>
            <AiOutlineCloseCircle />
          </span>
        </span>
      ) : (
        <div className="form__control">
          <input
            className="search__input"
            type="text"
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="carriers__items">
            {carriers.length > 0
              ? carriers.map((item, idx) => {
                  return (
                    <div className="carriers__item" key={idx}>
                      <p className="">{item.NDOV}</p>

                      <span
                        onClick={(e) => handleZamData(item)}
                        className="normal"
                        style={{ cursor: "context-menu", padding: "0.4rem" }}
                      >
                        Обрати замовника
                      </span>
                    </div>
                  );
                })
              : "Напишіть назву компанії"}
          </div>
        </div>
      )}

      <div className="form__control">
        <GooglePlacesAutocomplete
          className="okkk"
          apiKey="AIzaSyDddHSvr8KFFahBGyqLCQVxpjCsFw-p5ek"
          apiOptions={{
            language: "uk",
          }}
          selectProps={{
            zav,
            onChange: setZav,
            placeholder: "Місто завантаження",
          }}
        />
      </div>
      <div className="form__control">
        <GooglePlacesAutocomplete
          apiKey="AIzaSyDddHSvr8KFFahBGyqLCQVxpjCsFw-p5ek"
          apiOptions={{
            language: "uk",
          }}
          selectProps={{
            rozv,
            onChange: setRozv,
            placeholder: "Місто вивантаження",
          }}
        />
      </div>
      <div className="form__control">
        <label className="cina__zap">Запит ціни</label>
        <input
          type="checkbox"
          checked={zapCina}
          onChange={handleCheckboxChange}
        />
      </div>
      <div className="form__control">
      <label className="cina__zap">Збірний вантаж</label>
        <input
          type="checkbox"
          checked={zbir}
          onChange={handleZbirChange}
        />
      </div>
      <div  className="form__control">
  <select onChange={handleChangeTypeTz} value={selectedTypeTz}  style={{width:'100%'}} name="tztype" id="tztype">
    {tzType && tzType.filter(item => item).sort((a,b) => b.SORTID > a.SORTID).map((item,idx) =>{
      return <option value={item.KOD}>{item.NTYPE}</option>
    })}
  </select>

      </div>
      <div className="form__control">
        <label className="cina__zap">К-сть авто</label>
        <input
          type="number"
          min="0"
          max="100"
          value={countCar}
          onChange={handleCountCarsChange}
        />
      </div>
      <div className="form__control">
        <textarea
          onChange={(e) => setZapText(e.target.value)}
          type="text"
          placeholder="Додаткова інформація по вантажу"
        />
      </div>

      <div className="per__info">
        <button onClick={(e)=>{
          e.stopPropagation();
          e.preventDefault()
          setPerinfo(val => !val)
        }}>{perInfo ? 'Приховати':'Інформація для перевізника'}</button>
      </div>
{perInfo &&       <div className="form__control per-control">
        <textarea
          onChange={(e) => setZapTextPriv(e.target.value)}
          type="text"
          placeholder="Додаткова інформація по вантажу(для перевізника)"
        />
       <div>
       <input onChange={e => setZapPerCina(e.target.value)}  type="text" placeholder="Ціна для перевізника"/>
       </div>
      </div>}


      <button className="normal">Додати</button>
    </form>
  );
};

export default AddZap;
