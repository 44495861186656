const converLinkToPageName = (str) => {
    switch (str) {
        case '/ukraine-cargo':
            return 'ПЕРЕВЕЗЕННЯ ВАНТАЖІВ ПО ТЕРИТОРІЇ УКРАЇНИ';
        // Можна додати інші випадки, якщо потрібно
        case '/adr-cargo':
            return 'ПЕРЕВЕЗЕННЯ НЕГАБАРИТНИХ ТА НЕБЕЗПЕЧНИХ ADR ВАНТАЖІВ';
        case '/international-cargo':
            return 'МІЖНАРОДНІ ВАНТАЖНІ ПЕРЕВЕЗЕННЯ';
        case '/collective-cargo':
            return 'ОРГАНІЗАЦІЯ ПЕРЕВЕЗЕНЬ ЗБІРНИХ ВАНТАЖІВ';
        case '/container-cargo':
            return 'ПЕРЕВЕЗЕННЯ МОРСЬКИМИ КОНТЕЙНЕРАМИ';
        case '/container-storage':
            return 'ОРЕНДА КОНТЕЙНЕРІВ ДЛЯ ІНДИВІДУАЛЬНОГО ЗБЕРІГАННЯ';

        default:
            return str; // Повертає оригінальний рядок, якщо немає відповідності
    }
}

export default converLinkToPageName;
