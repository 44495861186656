import React, { useState } from 'react';
import axios from 'axios';
import instance from '../../utils/axios';

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [fileUrl, setFileUrl] = useState('');


  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleFileNameChange = (e) => {
    setFileName(e.target.value); // Set the custom file name
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file to upload.');
      return;
    }

    if (!fileName) {
      alert('Please enter a custom file name.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file); // Append the selected file
    formData.append('fileName', fileName); // Append the custom file name

    try {
      const response = await instance.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-filename': encodeURIComponent(fileName),  // Додаємо ім'я файлу в заголовки
        },
      });

      console.log('File uploaded successfully:', response.data);
   setFileUrl(response.status)
   console.log(fileUrl);
   
    } catch (error) {
      console.error('Error uploading the file:', error);
    }
  };

  return (
    <div>
      <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
      <input
        type="text"
        placeholder="Введіть назву для файлу"
        value={fileName}
        onChange={handleFileNameChange}
      />
      <button onClick={handleUpload}>Завантажити</button>

      {fileUrl && (
        <div>
          <h3>Успішно</h3>
      
        </div>
      )}
    </div>
  );
};

export default FileUpload;
